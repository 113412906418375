import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LocalForm, actions } from 'commons/js/components/form'
import { Flex, Box, Copy } from '@elparking/components'
import { FormattedMessage } from 'react-intl'

import defaultCountry from 'commons/js/country'
import Routes from 'commons/js/routes/routes'
import LoaderButton from 'commons/js/ui/LoaderButton'
import { LOGGABLE_COUNTRIES } from 'commons/js/constants'
import FirstNameField from 'commons/js/ui/FirstNameField'
import LastNameField from 'commons/js/ui/LastNameField'
import EmailField from 'commons/js/ui/EmailField'
import { PasswordStrictField } from 'commons/js/ui/PasswordField'
import PromoCodeField from 'commons/js/ui/PromoCodeField'
import CountryField from 'commons/js/ui/CountryField'
import TermsField from 'commons/js/ui/TermsField'
import FieldGroup from 'commons/js/ui/FieldGroup'
import TermsLink from 'commons/js/ui/TermsLink'
import Logo from 'commons/js/ui/Logo'
import findCountryBy from './findCountryBy'

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
    promoCode: '',
    acceptConditions: false,
}

export const Errors = ({errors}) => (errors && errors.length > 0)
    ? <Box mb='8px' w='100%'>
        <Copy padding='0' color='red' size='small' fontWeight='regular'>
            { errors.map((error, index) => {
                if (Array.isArray(error)) {
                    return error[0].message
                }
                if (error.message && (typeof error.message !== 'string')) {
                    return <FormattedMessage key={`error-${index}`} defaultMessage='Se ha producido un error. Inténtalo de nuevo más tarde.' />
                }
                return error.message
            }).map((e, index) => <span key={`error_${index}`}>{e} </span>) }
        </Copy>
    </Box>
    : null

Errors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
            message: PropTypes.string,
        }),
        PropTypes.object,
        PropTypes.array,
    ])),
}
class RegisterForm extends Component {
    static propTypes = {
        errors: PropTypes.any,
        initialState: PropTypes.shape({
            promoCode: PropTypes.string,
        }),
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func,
        renderReCaptcha: PropTypes.func,
    }

    static defaultProps = {
        errors: [],
        disabled: false,
        onSubmit: () => {},
        renderReCaptcha: () => {},
    }

    state = {
        acceptedCountries: LOGGABLE_COUNTRIES,
        defaultCountry: findCountryBy(LOGGABLE_COUNTRIES, { iso3: defaultCountry.toUpperCase() }),
    }

    componentDidUpdate (_, prevState) {
        if (prevState.defaultCountry !== this.state.defaultCountry) {
            this.formDispatch(actions.change('registration.country', this.state.defaultCountry.iso3))
        }

        if (prevState.selectedCountry !== this.state.selectedCountry) {
            this.formDispatch(actions.reset('registration.acceptConditions'))
        }
    }

    attachDispatch = this.attachDispatch.bind(this)

    attachDispatch (dispatch) {
        this.formDispatch = dispatch
    }

    getInitialFormState (props) {
        return {
            ...initialState,
            ...props.initialState,
        }
    }

    onSubmit = this.onSubmit.bind(this)

    onSubmit (values) {
        const country = findCountryBy(this.state.acceptedCountries, { iso3: values.countryIso3 })

        this.props.onSubmit({
            ...values,
            country: country.iso2,
        })
    }

    onChange = this.onChange.bind(this)

    onChange (values) {
        this.setState(() => ({ selectedCountry: values.countryIso3 }))
    }

    renderTermsLink = this.renderTermsLink.bind(this)

    renderTermsLink (props) {
        return (
            <TermsLink
              {...props}
              countries={this.state.acceptedCountries}
              selectedCountry={this.state.selectedCountry}
            >
                <FormattedMessage
                  defaultMessage='términos y condiciones'
                />
            </TermsLink>
        )
    }
    render () {
        const { errors, loading, renderReCaptcha, disabled } = this.props
        const { acceptedCountries, defaultCountry } = this.state
        return (
            <LocalForm
              model='registration'
              onChange={this.onChange}
              onSubmit={this.onSubmit}
              initialState={this.getInitialFormState(this.props)}
              getDispatch={this.attachDispatch}
            >
                <Flex flexWrap='wrap'>
                    <Flex w='100%' p='10px 0 5px 0' justifyContent='center' mb='35px'>
                        <a href={Routes.homePath()}>
                            <Logo height='30px' />
                        </a>
                    </Flex>
                    <Box mb='16px'>
                        <Copy padding='0' size='medium' fontWeight='book'>
                            <FormattedMessage
                              defaultMessage='Rellena el formulario para el registro'
                            />
                        </Copy>
                    </Box>
                    <Errors errors={errors} />
                    <FieldGroup disabled={loading}>
                        <Box w='100%' flex='1 0 100%' mb='8px'>
                            <FirstNameField dataTest='register-first-name-field' />
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='8px'>
                            <LastNameField dataTest='register-last-name-field' />
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='8px'>
                            <EmailField dataTest='register-email-field' />
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='8px'>
                            <CountryField
                              countries={acceptedCountries}
                              defaultCountry={defaultCountry}
                            />
                        </Box>
                        <Box mt='24px' mb='16px' display={['none', 'block', 'block']}>
                            <Copy padding='0' size='medium' fontWeight='book'>
                                <FormattedMessage defaultMessage='Establece tu contraseña' />
                            </Copy>
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='24px'>
                            <PasswordStrictField
                              dataTest='register-password-field'
                              autoComplete='new-password'
                            />
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='16px'>
                            <PromoCodeField validateIcon={false} showExpanded={!!this.props.initialState.promoCode} />
                        </Box>
                        <Box w='100%' flex='1 0 100%' mb='16px'>
                            <TermsField
                              id='terms'
                              fontSize='xxSmall'
                              dataTest='register-terms-field'
                              renderTermsLink={this.renderTermsLink}
                            />
                        </Box>
                    </FieldGroup>
                    <LoaderButton dataTest='register-submit-button' formNoValidate loading={loading} disabled={disabled} model='registration' width='100%'>
                        <FormattedMessage
                          defaultMessage='Crear cuenta'
                        />
                    </LoaderButton>
                    <Box>
                        { renderReCaptcha() }
                    </Box>
                </Flex>
            </LocalForm>
        )
    }
}

export default RegisterForm
